<form [formGroup]="scheme">
    <mat-card class="mb-3 nested-card">
        <div class="grid lg:grid-cols-2 gap-3">
            <div>
          <mat-form-field class="example-full-width w-100" style="width: 50%;">
            <mat-label>Amount</mat-label>
            <input matInput placeholder="Enter amount " name="amount" formControlName="amount" />
            <mat-error>Amount is required</mat-error>
          </mat-form-field>

          <!-- <mat-form-field class="example-full-width w-50 mr-15" style="width: 50%;">
            <mat-label>Balanace</mat-label>
            <input matInput placeholder="Enter balance amount " name="balance" formControlName="balance" />
            <mat-error>Balanace amount is required</mat-error>
          </mat-form-field> -->
          
          </div>
        </div>
     
      </mat-card>
  </form>
  <mat-card>
    <div  class="flex flex-wrap justify-end mb-15">
        <button mat-raised-button color="warn" class="mr-15" style="margin-right: 15px;"  (click)="cancel()">
          Cancel
          </button>
          <button  class="ml-15" mat-raised-button color="primary" (click)="add()" [disabled]="scheme.invalid">
            Submit
          </button>
        </div>
      </mat-card>