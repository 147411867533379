import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { urls } from '../../constants/urls';
import { ApiService, UtilsService,ToastService } from '../../services';
@Component({
  selector: 'app-create-schem',
  templateUrl: './create-schem.component.html',
  styleUrls: ['./create-schem.component.scss']
})
export class CreateSchemComponent implements OnInit {

  scheme!: FormGroup;
  organizationId:any;
  terms :any =[{value:12},{value:24},{value:36},{value:48},{value:60}]

  constructor( public dialogRef: MatDialogRef<CreateSchemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils : UtilsService,
    private api:ApiService,
    private toast : ToastService) { }

   ngOnInit() {
    this.scheme = new FormGroup({
      name: new FormControl(this.data ? this.data.name : '',Validators['required']),
      tenure: new FormControl(this.data ? this.data.tenure : '',Validators['required']),
      amount: new FormControl(this.data ? this.data.amount : '',Validators['required']),

    });
  }
  get f() {
    return this.scheme.controls;
  }

  async add(){
    this.organizationId = await this.utils.getOrganiztionId();
    this.scheme.value.organisationId =  this.organizationId;
    this.scheme.value.customerId =  this.data.customerId;
    const config ={
      url:urls.savingsCreate,
      payload:this.scheme.value
    }
    this.api.post(config).subscribe(resp =>{
      if(resp.result){
        this.toast.success(resp.message)
        this.dialogRef.close();
      }else{
        this.toast.error(resp.message);
      }
    })
  }
  cancel(){
    this.dialogRef.close();
  }

}
