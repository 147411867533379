export const urls = {
  login: "v1/account/login",
  product: "v1/product/read/", //organization id pass
  products: "v1/product/list/", //organization id pass
  create_product: "v1/product/create",
  update_product: "v1/product/update/", //  organization id pass
  organization_create: "v1/organisation/create",
  organization_update: "v1/organisation/update",
  organization_read: "v1/organisation/read/",
  playstation_list: "v1/playstation/list/",
  playstation_update: "v1/playstation/update/",
  playstation_create: "v1/playstation/create",
  customer: "v1/account/list/",
  invoice: "v1/sales/create",
  add_customer: "v1/account/create",
  invoices: "v1/sales/list/",
  employeeAdd: "v1/systemUser/create",
  contractList: "v1/contract/list/",
  pledge: "v1/contract/create",
  getSignedURL: "v1/cloud-services/file/getSignedUrl?fileName=",
  savingsCreate: "v1/savings/create",
  savingsList: "v1/savings/list/",
  contractInstallmentPay: "v1/contract/addInstallments/",
  contractUpdate: "v1/contract/update/",
  savingsyId: "v1/savings/read/",
  savingsUpdate: "v1/savings/update/",
  savingsInstallments: "v1/savings/addInstallments/",
  downloadableUrl: "v1/cloud-services/file/getDownloadableUrl?filePath=",
  updateAccount: "v1/profile/update/",
  takeOverList: "v1/takeOver/list/",
  takeOverUpdate: "v1/takeOver/update/",
  takeOverCreate: "v1/takeOver/create",
  generateInvoive: "v1/contract/generatePdf/",
  installmentPdf: "v1/contract/installmentPdf/",
  savingsInstallmentPDF: "v1/savings/installmentPdf/",
  takeOverPDF: "v1/takeOver/generatePdf/",
};
