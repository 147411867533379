<div class="bg">
    <div style="padding-top:10%">
      <mat-card>
        <div class="logo m-auto my-2">
          <a href="#">
            <img src="assets/images/logo.jpeg" alt="logo of expertizzy">
          </a>
        </div>
        <mat-card-content>
          <form #loginForm="ngForm">
            <mat-form-field>
              <input matInput placeholder="Username" [(ngModel)]="user.mobile" name="username"  autocomplete="false"  required>
              <mat-error>
                Please provide a valid email address
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput [type]="hide ? 'password' : 'text'" required placeholder="Password" [(ngModel)]="user.password" name="password"  autocomplete="false" >
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
            <div class="flex justify-end">
            <!-- <a class="no-underline" routerLink="../forgot-password">Forgot password </a> -->
            </div>
  
            <div class="my-3">
              <button mat-raised-button class="btn-button w-full" [disabled]="!loginForm.form.valid" (click)="submit()">Login</button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  