<div class="toolbar-footer">
  <div class="flex justify-between">
    <div></div>
    <div class="text-footer">
      <div style="font-size: 22px">Sagar gold</div>
      <div style="font-size: 14px">Market Road Sagar - 577401</div>
      <div style="font-size: 14px">Mobile: 9169162280</div>
    </div>
  </div>
</div>
