<form [formGroup]="customer">
  <mat-card class="mb-3 nested-card">
    <div class="grid lg:grid-cols-2 gap-3">
      <div>
        <!-- <h1> Add  </h1> -->
        <mat-form-field class="example-full-width w-100">
          <mat-label>Name <span class="mandatory-field">*</span></mat-label>
          <input
            matInput
            placeholder="Enter name"
            name="name"
            formControlName="name"
          />
          <mat-error>Name is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width w-100">
          <mat-label>Email <span class="mandatory-field">*</span></mat-label>
          <input
            matInput
            placeholder="Enter  email"
            name="email"
            formControlName="email"
          />
          <mat-error
            *ngIf="f['email'].touched && f['email'].invalid"
            class="text-red-500"
          >
            <mat-error
              *ngIf="f['email'].errors && f['email'].errors['required']"
              >Email id is required.
            </mat-error>
            <mat-error *ngIf="f['email'].errors && f['email'].errors['pattern']"
              >Please, enter valid email address.
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width w-100">
          <mat-label>Mobile number <span class="mandatory-field">*</span></mat-label>
          <input
            matInput
            placeholder="Enter mobile number"
            name="mobile"
            maxlength="10"
            formControlName="mobile"
            numbersOnly
          />
          <mat-error
            *ngIf="f['mobile'].touched && f['mobile'].invalid"
            class="text-red-500"
          >
            <mat-error
              *ngIf="f['mobile'].errors && f['mobile'].errors['required']"
              >Mobile number is required.
            </mat-error>
            <mat-error
              *ngIf="f['mobile'].errors && f['mobile'].errors['pattern']"
              >Please, enter valid mobile number.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="example-full-width w-100"
          *ngIf="data?.type == 'employee'"
        >
          <mat-label>Password <span class="mandatory-field">*</span></mat-label>
          <input
            matInput
            placeholder="Enter password"
            name="password"
            formControlName="password"
          />
          <mat-error
            *ngIf="f['password'].touched && f['password'].invalid"
            class="text-red-500"
          >
            <mat-error
              *ngIf="f['password'].errors && f['password'].errors['required']"
              >Password is required.
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field
        class="example-full-width w-100"
        *ngIf="data?.type == 'customer'"
      >
        <mat-label>Aadhar number <span class="mandatory-field">*</span></mat-label>
        <input
          matInput
          placeholder="Enter aadhar number"
          name="address"
          formControlName="aadharNumber"
        />
        <mat-error>
        <mat-error
          *ngIf="f['aadharNumber'].touched && f['aadharNumber'].invalid"
          class="text-red-500"
        > Enter valid Aadhar number</mat-error>
          <mat-error
            *ngIf="f['aadharNumber'].errors && f['aadharNumber'].errors['required']"
            >Aadhar is required.
          </mat-error>
       
      </mat-error>
      </mat-form-field>
      <mat-form-field
          class="example-full-width mr-15 width-1"
          *ngIf="data?.type == 'customer'"
        >
          <mat-label>Nominee <span class="mandatory-field">*</span></mat-label>
          <input
            matInput
            placeholder="Enter nominee name"
            name="nominee"
            formControlName="nominee"
          />
          <mat-error
            *ngIf="f['nominee'].touched && f['nominee'].invalid"
            class="text-red-500"
          >
            <mat-error
              *ngIf="f['nominee'].errors && f['nominee'].errors['required']"
              >Nominee name is required.
            </mat-error>
          </mat-error>
        </mat-form-field>
     
      <mat-form-field class="example-full-width width-1">
        <mat-label
          >Relation with nominee <span class="mandatory-field">*</span></mat-label
        >
        <!-- <input matInput placeholder="Enter loan amount" name="terms" formControlName="terms" /> -->
        <mat-select name="relationShip" formControlName="relationShip">
          <mat-option *ngFor="let relation of relations" [value]="relation.value">
            {{ relation?.label }}
          </mat-option>
        </mat-select>
        <mat-error>Relation with nominee is required</mat-error>
      </mat-form-field>
        <mat-form-field
          class="example-full-width w-100"
          *ngIf="data?.type == 'customer'"
        >
          <mat-label>Address <span class="mandatory-field">*</span></mat-label>
          <input
            matInput
            placeholder="Enter address"
            name="address"
            formControlName="address"
          />
          <mat-error
            *ngIf="f['address'].touched && f['address'].invalid"
            class="text-red-500"
          >
            <mat-error
              *ngIf="f['address'].errors && f['address'].errors['required']"
              >Address is required.
            </mat-error>
          </mat-error>
        </mat-form-field>

        <div *ngIf="data?.type == 'customer'" class="flex"> 
          <div class="fileControl-wrapper">
            <!-- <mat-toolbar style="padding: 0 0px;"> -->
            <input matInput readonly name="File" hidden />
            <img
              src="assets/images/file-upload.png"
              style="width: 70px"
              (click)="uploadControl.click()"
            />
            <!-- </mat-toolbar> -->
            <input
              type="file"
              #uploadControl
              id="fileControlInput"
              (change)="onFileChange($event)"
              name="fileControlInput"
            />
          </div>
          <img *ngIf ="data?.data?.image" src="{{data?.data?.image}}" style="width: 100px; height:100px; border-radius: 50%;">
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-end mb-15">
      <button
        mat-raised-button
        color="warn"
        class="mr-15"
        style="margin-right: 15px"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button class="ml-15" mat-raised-button color="primary" (click)="add()" [disabled]="!customer.valid">
        Submit
      </button>
    </div>
  </mat-card>
</form>
