<form [formGroup]="customer">
  <mat-card class="mb-3 nested-card">
    <div class="grid lg:grid-cols-2 gap-3">
      <div>
        <h1></h1>
        <mat-form-field class="example-full-width w-100">
          <mat-label>Title <span class="mandatory-field">*</span></mat-label>
          <input
            matInput
            placeholder="Enter Title"
            name="name"
            formControlName="name"
          />
          <mat-error>Title is required</mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap w-100">
        <mat-form-field class="example-full-width mr-15" style="width: 25%">
          <mat-label
            >Purchased amount <span class="mandatory-field">*</span></mat-label
          >
          <input
            matInput
            placeholder="Enter loan amount"
            name="purchasedAmount"
            formControlName="purchasedAmount"
          />
          <mat-error>Purchased amount is required</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
</form>
<form [formGroup]="items">
  <div>
    <!-- <mat-card> -->
    <div *ngIf="items">
      <div class="flex justify-between mt-15">
        Items :
        <button mat-raised-button (click)="addItems()">Add items</button>
      </div>
      <mat-card
        style="
          margin: 10px 0px;
          background-color: #f4f4f4;
          position: relative;
          overflow: auto;
          max-height: 300px;
        "
      >
        <ng-container
          formArrayName="item"
          style="overflow: auto; max-height: 300px"
        >
          <ng-container *ngFor="let lessonForm of item.controls; let i = index">
            <mat-card
              [formGroupName]="i"
              class="mb-3 nested-card"
              style="margin: 10px 0px"
            >
              <mat-icon class="close-icon" (click)="deleteFeild(i)">
                close
              </mat-icon>
              <mat-form-field class="example-full-width mr-15 width-1">
                <mat-label
                  >Name <span class="mandatory-field">*</span></mat-label
                >
                <input
                  matInput
                  placeholder="Enter item name"
                  name="name"
                  formControlName="name"
                />
                <mat-error>Name is required</mat-error>
              </mat-form-field>
              <mat-form-field class="example-full-width width-1">
                <mat-label
                  >qty <span class="mandatory-field">*</span></mat-label
                >
                <input
                  matInput
                  placeholder="Enter Qty"
                  name="qty"
                  formControlName="qty"
                />
                <mat-error>Qty is required</mat-error>
              </mat-form-field>
              <mat-form-field class="example-full-width mr-15 width-1">
                <mat-label
                  >Weight <span class="mandatory-field">*</span></mat-label
                >
                <input
                  matInput
                  placeholder="Enter weight "
                  name="weight"
                  formControlName="weight"
                />
                <mat-error>Weight is required</mat-error>
              </mat-form-field>
              <mat-form-field class="example-full-width width-1">
                <mat-label
                  >Description <span class="mandatory-field">*</span>
                </mat-label>
                <input
                  matInput
                  placeholder="Enter description"
                  name="description"
                  formControlName="description"
                />
                <mat-error>Description is required</mat-error>
              </mat-form-field>
              <div>
                <div class="fileControl-wrapper">
                  <!-- <mat-toolbar style="padding: 0 0px;"> -->
                  <input matInput readonly name="File" hidden />
                  <img
                    src="assets/images/file-upload.png"
                    style="width: 70px"
                    (click)="uploadControl.click()"
                  />
                  <!-- </mat-toolbar> -->
                  <input
                    type="file"
                    #uploadControl
                    id="fileControlInput"
                    (change)="onFileChange($event, lessonForm)"
                    name="fileControlInput"
                  />
                </div>
              </div>
            </mat-card>
          </ng-container>
        </ng-container>
      </mat-card>
    </div>
  </div>
</form>
<div class="flex flex-wrap justify-end mb-15">
  <button
    mat-raised-button
    color="warn"
    class="mr-15"
    style="margin-right: 15px"
    (click)="cancel()"
  >
    Cancel
  </button>
  <button class="ml-15" mat-raised-button color="primary" (click)="add()">
    Submit
  </button>
</div>
