import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { urls } from "../../constants/urls";
import { ApiService, UtilsService, ToastService, SpinnerService } from "../../services";

@Component({
  selector: "app-add-customer",
  templateUrl: "./add-customer.component.html",
  styleUrls: ["./add-customer.component.scss"],
})
export class AddCustomerComponent implements OnInit {
  customer!: FormGroup;
  organizationId: any;
  constructor(
    public dialogRef: MatDialogRef<AddCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: UtilsService,
    private api: ApiService,
    private toast: ToastService,
    private spinner: SpinnerService
  ) {}

  relations =[{label:'Father',value:'father'},
  {label:'Mother',value:'mother'},
  {label:'Spouse',value:'spouse'},
  {label:'Sister',value:'sister'},
  {label:'Brother',value:'brother'},
  {label:'Son',value:'son'},
  {label:'Daughter',value:'daughter'}]
  ngOnInit() {
    if (this.data?.type != "customer") {
      this.customer = new FormGroup({
        name: new FormControl(this.data?.data ? this.data?.data?.name : ""),
        mobile: new FormControl(this.data?.data ? this.data?.data?.mobile?.number : "", [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        ]),
        email: new FormControl(this.data?.data? this.data?.data?.email : "", [
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]),
        password: new FormControl(this.data?.data? this.data?.data?.password : ""),
      });
    } else if (this.data?.type == "customer") {
      this.customer = new FormGroup({
        name: new FormControl(this.data?.data ? this.data?.data?.name : "", [
          Validators.required]),
        mobile: new FormControl(this.data?.data ? this.data?.data?.mobile?.number : "", [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
        ]),
        email: new FormControl(this.data?.data ? this.data?.data?.email : "", [Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ]),
        address: new FormControl(this.data?.data ? this.data?.data?.address : "",[
          Validators.required]),
        aadharNumber: new FormControl(this.data?.data ? this.data?.data?.aadharNumber : "", [
          Validators.required
        ]),
        // Validators.pattern("^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$"),
        nominee:new FormControl(this.data?.data ? this.data?.data?.nominee : "",[
          Validators.required]),
        relationShip:new FormControl(this.data?.data ? this.data?.data?.nomineeRelation : "",[
          Validators.required]),
        // image:new FormControl(this.data ? this.data?.data.image : ""),
      });
    }
  }
  get f() {
    return this.customer.controls;
  }

  async add() {
    this.organizationId = await this.utils.getOrganiztionId();
    this.customer.value.organisationId = this.organizationId;
    const config = {
      url: urls.add_customer,
      payload: this.customer.value,
    };
    if (this.data == "employee") {
      config.payload.type = "employee";
    }
    this.api.post(config).subscribe((resp) => {
      if (resp.result) {
        this.toast.success(resp.message);
        this.dialogRef.close();
      } else {
        // this.toast.error(resp.message);
      }
    });
  }
  cancel() {
    this.dialogRef.close();
  }

  onFileChange(e: any) {
    let formData = new FormData();
    if (e.target.files && e.target.files[0]) {
      Array.from(e.target.files).forEach((file: any) => {
        // formData.append('files',file)
        let d = new Date(),
          n = d.getTime();
        let extentsion = file.name.split(".").pop();
        const config = {
          url: urls.getSignedURL + n + "." + extentsion,
          payload: formData,
        };
        this.spinner.show();
        this.api.get(config).subscribe((resp) => {
          let config1 = {
            url: resp.result.signedUrl,
            payload: file,
          };
          return this.api.imageUpload(config1).subscribe((data) => {
            const downlodUrl = {
              url: urls.downloadableUrl + resp.result.filePath,
            };
              this.api.get(downlodUrl).subscribe((data) => {
                this.customer.value.image = data.result;
                this.spinner.hide();
                this.data && this.data.data ? this.data.data.image = data.result :''
              });
          });
        });
      });
    }
  }
}
