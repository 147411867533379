<form [formGroup]="customer">
    <mat-card class="mb-3 nested-card">
        <div class="grid lg:grid-cols-2 gap-3">
            <div>
              <h1> Add customer </h1>
          <mat-form-field class="example-full-width w-100">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter customer name" name="name" formControlName="name" />
            <mat-error>Name is required</mat-error>
          </mat-form-field>
          </div>
          <div>
          <mat-form-field class="example-full-width w-100">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Enter customer email" name="email"
              formControlName="email" />
              <mat-error *ngIf="f['email'].touched && f['email'].invalid" class="text-red-500">
                <mat-error *ngIf="f['email'].errors && f['email'].errors['required']">Email id is required.
                </mat-error>
                <mat-error *ngIf="f['email'].errors && f['email'].errors['pattern']">Please, enter valid email
                  address.
                </mat-error>
              </mat-error>
          </mat-form-field>
          </div>
          <div>
          <mat-form-field class="example-full-width w-100">
            <mat-label>Mobile number</mat-label>
            <input matInput placeholder="Enter customer mobile number" name="mobile" maxlength="10"
              formControlName="mobile" numbersOnly>
              <mat-error *ngIf="f['mobile'].touched && f['mobile'].invalid" class="text-red-500">
                <mat-error *ngIf="f['mobile'].errors && f['mobile'].errors['required']">Mobile number is required.
                </mat-error>
                <mat-error *ngIf="f['mobile'].errors && f['mobile'].errors['pattern']">Please, enter valid mobile number.
                </mat-error>
              </mat-error>
          </mat-form-field>
          </div>

        </div>
        <div  class="flex flex-wrap justify-end mb-15">
          <button mat-raised-button color="warn" class="mr-15" style="margin-right: 15px;"  (click)="cancel()">
            Cancel
            </button>
            <button  class="ml-15" mat-raised-button color="primary" (click)="add()">
              Submit
            </button>
          </div>
      </mat-card>
  </form>