<form [formGroup]="scheme">
    <mat-card class="mb-3 nested-card">
        <div class="grid lg:grid-cols-2 gap-3">
            <div>
              <h1> Add scheme </h1>
          <mat-form-field class="example-full-width w-50 mr-15" style="width: 50%;">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter name " name="name" formControlName="name" />
            <mat-error>Name is required</mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width w-50"  style="width: 50%;">
            <mat-label>No of tenures</mat-label>
            <mat-select name="tenure" formControlName="tenure">
              <mat-option *ngFor="let term of terms" [value]="term.value">
                {{term?.value}}
              </mat-option>
            </mat-select>
            <mat-error>Number of tenure is required</mat-error>
          </mat-form-field>
          <mat-form-field class="example-full-width w-100">
            <mat-label>Amount</mat-label>
            <input matInput placeholder="Enter amount " name="amount" formControlName="amount" />
            <mat-error>Amount is required</mat-error>
          </mat-form-field>
          </div>
        </div>
     
      </mat-card>
  </form>
  <mat-card>
    <div  class="flex flex-wrap justify-end mb-15">
        <button mat-raised-button color="warn" class="mr-15" style="margin-right: 15px;"  (click)="cancel()">
          Cancel
          </button>
          <button  class="ml-15" mat-raised-button color="primary" (click)="add()" [disabled]="scheme.invalid">
            Submit
          </button>
        </div>
      </mat-card>