import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup,FormBuilder, Validators,FormArray } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiService, ToastService, UtilsService } from 'src/app/core';
import { urls } from 'src/app/core/constants/urls';

@Component({
  selector: 'app-add-take-over',
  templateUrl: './add-take-over.component.html',
  styleUrls: ['./add-take-over.component.scss']
})
export class AddTakeOverComponent implements OnInit {


  customer!: FormGroup;
  items!: FormGroup;
  organizationId:any;
  constructor( public dialogRef: MatDialogRef<AddTakeOverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils : UtilsService,
    private api:ApiService,
    private toast : ToastService,
    private fb: FormBuilder,) { }
    statuses:any =[{
      value:"active"
    },{
      value:"closed"
    }];
    terms :any =[{value:12},{value:24},{value:36},{value:48},{value:60}]

   ngOnInit() {
    this.customer = new FormGroup({
      organisationId: new FormControl(this.data ? this.data.organisationId : '',[Validators.required]),
      name: new FormControl(this.data ? this.data.name : '',[Validators.required]),
      purchasedAmount: new FormControl(this.data ? this.data.purchasedAmount : '',[Validators.required]),
      customerId: new FormControl(this.data ? this.data.customerId : '',[Validators.required]),
      status: new FormControl('active',[Validators.required]),
    });
    this.items = this.fb.group({
      item: this.fb.array([])
    });

    this.addFeildsFirstTime();
  }
  get f() {
    return this.customer.controls;
  }
  get item()  {
    return <FormArray>this.items.controls['item'] as FormArray
  }

  async add(){
    this.organizationId = await this.utils.getOrganiztionId();
    this.customer.value.organisationId =  this.organizationId;
    this.customer.value.customerId = this.data.customerId;
    this.customer.value.items = this.items.value.item;
    this.customer.value.dueDate ="ewrre"
    const config ={
      url:urls.takeOverCreate,
      payload:this.customer.value
    }
    this.api.post(config).subscribe(resp =>{
      if(resp.result){
        this.toast.success(resp.message)
        this.dialogRef.close();
      }else{
        this.toast.error(resp.message);
      }
    })
  }
  cancel(){
    this.dialogRef.close();
  }
  addItems(){
    let fields: any = '';
    fields = this.fb.group({
      name: new FormControl('',[Validators.required]),
      image: new FormControl('', [Validators.required]),
      qty: new FormControl('', [Validators.required]),
      weight: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),

    })
    this.item.push(fields);
  }

  deleteFeild(i){
    this.item.removeAt(i);
  }

  addFeildsFirstTime() {
    if (this.data && this.data.items) {
      this.data.items.forEach((element: any) => {
        let fieldsExp = this.fb.group({
          name: new FormControl(element.name, []),
          image: new FormControl(element.image, []),
          qty: new FormControl(element.aty, []),
          weight: new FormControl(element.weight, []),
          description: new FormControl(element.description, []),
        });
        this.item.push(fieldsExp);
      });
    } else {
      let fields: any = '';
      fields = this.fb.group({
        name: new FormControl('', [Validators.required]),
        image: new FormControl('', [Validators.required]),
        qty: new FormControl('', [Validators.required]),
        weight: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
      })
      this.item.push(fields);
    }
  }


  onFileChange(e: any, item) {
    let formData = new FormData();
    if (e.target.files && e.target.files[0]) {
      Array.from(e.target.files).forEach((file: any) => {
        // formData.append('files',file)
        let d = new Date(),
         n = d.getTime();
        let  extentsion = file.name.split(".").pop();
        const config ={
          url : urls.getSignedURL + n+'.'+extentsion ,
          payload:formData
        }
        this.api.get(config).subscribe(resp =>{
          let config1 ={
            url : resp.result.signedUrl,
            payload:file
          }
          return this.api.imageUpload(config1).subscribe(data =>{
            const downlodUrl={
              url : urls.downloadableUrl + resp.result.filePath
            }
            this.api.get(downlodUrl).subscribe(data =>{
              item.value.image = data.result;
            })
          })
        })
      });
    }
  }

}
