import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { urls } from "../../constants/urls";
import { ApiService, UtilsService } from "../../services";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-installments",
  templateUrl: "./installments.component.html",
  styleUrls: ["./installments.component.scss"],
})
export class InstallmentsComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InstallmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private utils: UtilsService
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  download(installment, item) {
    this.utils.downloadPDF(
      environment.apiBaseUrl +
        this.data.url +
        item._id +
        "?installmentId=" +
        installment._id,
      "installment"
    );
  }
}
