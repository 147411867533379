import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
// import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class SpinnerService {
  visibility = new Subject<any>();
  count = 0;
  constructor() {}

  show() {
    this.visibility.next(true);
  }

  hide() {
    this.visibility.next(false);
  }
}
