import { Injectable } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ApiService } from "./api/api.service";
import { CurrentUserService } from "./current-user/current-user/current-user.service";
import { observable, Subject } from "rxjs";
import { GenericConfirmPopupComponent } from "../components/generic-confirm-popup/generic-confirm-popup.component";
@Injectable({
  providedIn: "root",
})
export class UtilsService {
  // list = menu;
  logoUpdatedEvent = new Subject<any>();
  yearUpdatedEvent = new Subject<any>();
  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private userService: CurrentUserService
  ) {}

  openDialog(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(GenericConfirmPopupComponent, {
        width: "250px",
        data: payload,
      });
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }

  downloadPDF(pdfUrl: any, fileName: any, type?: any) {
    let extension = type ? ".xlsx" : ".pdf";
    window.open(pdfUrl, "_blank");
    // fetch(
    //   "https://sagaracmcprograms.in/gold/v1/contract/installmentPdf/6436fc1261cb68ab193cd2e3?installmentId=6440069361480b5aac2a62c6"
    // )
    //   .then((resp: any) => {
    //     // if (resp) {
    //     resp.arrayBuffer();
    //     // }
    //   })
    //   .then(
    //     (result: any) => {
    //       // if (result) {
    //       console.log("ffd");
    //       const file = new Blob([result], {
    //         type: type ? type : "application/pdf",
    //       });
    //       const fileURL = URL.createObjectURL(file);
    //       const link = document.createElement("a");
    //       link.href = fileURL;
    //       link.download = fileName + extension;
    //       link.click();
    //       // }
    //     },
    //     (error) => {}
    //   );
  }

  logoUpdated() {
    this.logoUpdatedEvent.next(true);
  }

  yearUpdated() {
    this.yearUpdatedEvent.next(true);
  }

  getOrganiztionId() {
    return new Promise((resolve, reject) => {
      let data: any = localStorage.getItem("cu");
      data = JSON.parse(data);
      if (data) {
        resolve(data.user.organisationId);
      }
    });
  }
}
