import { Location } from "@angular/common";
import { Component, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { ApiService, ToastService, UtilsService } from "src/app/core";
import { AddCustomerComponent } from "src/app/core/components/add-customer/add-customer.component";
import { urls } from "src/app/core/constants/urls";

@Component({
  selector: "app-customers-list",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.scss"],
})
export class CustomersComponent implements OnInit {
  displayedColumns = ["id", "name", "mobile", "address"];
  customers: any = [];
  count: number;
  searchText: string = "";
  page = 1;
  @Input() type = "";
  @Output() onClickEvent = new Subject();
  constructor(
    private matdialog: MatDialog,
    private api: ApiService,
    private toast: ToastService,
    private util: UtilsService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.type == "employee") {
      this.displayedColumns = ["name", "mobile"];
    } else {
      this.displayedColumns = ["id", "name", "mobile", "address","actions"];
    }
    this.getcustomers();
  }

  back() {
    this.location.back();
  }
  loadMore() {
    this.count = 0;
    this.page = this.page + 1;
    this.getcustomers();
  }
  search() {
    this.count = 0;
    this.page = 1;
    this.customers = [];
    this.getcustomers();
  }
  addCustomer() {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.matdialog.open(AddCustomerComponent, {
      width: "60%",
      data: {type : this.type,data:""},
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.customers = [];
      this.count = 0;
      this.page = 1;
      this.getcustomers();
    });
  }

  async getcustomers() {
    let orgId = await this.util.getOrganiztionId();
    let url = urls.customer + orgId;
    if (this.type && this.type == "employee") {
      url =
        url +
        "?type=employee" +
        "&search=" +
        this.searchText.trim() +
        "&page=" +
        this.page +
        "&limit=10";
    } else {
      url =
        url +
        "?type=customer" +
        "&search=" +
        this.searchText.trim() +
        "&page=" +
        this.page +
        "&limit=10";
    }
    const config = {
      url: url,
    };
    this.api.get(config).subscribe((resp) => {
      if (resp.result) {
        this.customers = this.customers.concat(resp.result[0].data);
        this.count = resp.result[0].count;
      }
    });
  }
  onClick(element) {
    this.onClickEvent.next(element);
  }

  edit(item) {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.matdialog.open(AddCustomerComponent, {
      width: "60%",
      data: {type : this.type,data:item},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
}
