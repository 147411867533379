import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { CurrentUserService } from "../current-user/current-user/current-user.service";
import { ToastService } from "../toast.service";
import { environment } from "src/environments/environment";
import { SpinnerService } from "../spinner.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  popOpen: boolean = false;
  baseUrl: string = "";
  // auth: AuthService;
  header = "";
  constructor(
    public http: HttpClient,
    public userService: CurrentUserService,
    public toastService: ToastService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {}

  get(requestParam: any) {
    this.baseUrl = this.baseUrl ? this.baseUrl : environment.appBase;
    this.spinnerService.show();
    return this.http
      .get(environment.apiBaseUrl + this.baseUrl + requestParam.url)
      .pipe(
        tap(
          (data: any) => {
            this.spinnerService.hide();
            if (data.status == 401) {
              // this.userService.deleteUser().then(resp =>{
              //   this.router.navigate(['/login']);
              // })
            } else {
              return data;
            }
          },
          (error) => {
            this.spinnerService.hide();
          }
        ),
        catchError(this.handleError([]))
      );
  }

  post(requestParam: any) {
    this.baseUrl = this.baseUrl ? this.baseUrl : environment.appBase;
    this.spinnerService.show();
    return this.http
      .post(
        environment.apiBaseUrl + this.baseUrl + requestParam.url,
        requestParam.payload
      )
      .pipe(
        tap((data: any) => {
          if (data.status == 401) {
            this.userService.deleteUser().then((resp) => {
              this.router.navigate(["/login"]);
              this.spinnerService.hide();
            });
          } else {
            this.spinnerService.hide();
            return data;
          }
        }),
        catchError(this.handleError([]))
      );
  }

  put(requestParam: any): Observable<any> {
    this.spinnerService.show();
    return this.http
      .put(
        environment.apiBaseUrl + this.baseUrl + requestParam.url,
        requestParam.payload
      )
      .pipe(
        tap(
          (data) => {
            this.spinnerService.hide();
            return data;
          },
          (error) => {
            this.spinnerService.hide();
          }
        ),
        catchError(this.handleError([]))
      );
  }

  imageUpload(requestParam: any): Observable<any> {
    var options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.http.put(requestParam.url, requestParam.payload, options).pipe(
      tap(
        (data) => {
          return data;
        },
        (error) => {}
      ),
      catchError(this.handleError([]))
    );
  }

  delete(requestParam: any): Observable<any> {
    this.spinnerService.show();
    return this.http
      .delete(environment.apiBaseUrl + this.baseUrl + requestParam.url)
      .pipe(
        tap(
          (data) => {
            this.spinnerService.hide();
            return data;
          },
          (error) => {
            this.spinnerService.hide();
          }
        ),
        catchError(this.handleError([]))
      );
  }
  patch(requestParam: any): Observable<any> {
    return this.http
      .patch(
        environment.apiBaseUrl + this.baseUrl + requestParam.url,
        requestParam.payload
      )
      .pipe(
        tap(
          (data) => {
            return data;
          },
          (error) => {}
        ),
        catchError(this.handleError([Error]))
      );
  }

  private handleError(result: any) {
    return (error: any): Observable<any> => {
      this.spinnerService.hide();
      if (error.status === 404) {
        this.toastService.error(
          "Somthing went wrong, please try again after sometime."
        );
      } else if (error.status === 401) {
        if (error.error.message == "permission denied") {
          this.toastService.error("Permission Denied");
        } else {
          this.toastService.error("Authentication failed, please login again");
          this.userService.deleteUser().then((resp) => {
            this.router.navigate(["/login"]);
          });
        }
      } else {
        this.toastService.error(error.error.message);
      }
      return of(result);
    };
  }

  getToken() {
    //  return this.auth.getUser();
  }
}
