<div class="m16">
  <div class="flex flex-wrap justify-between">
    <mat-icon (click)="back()" class="cursor-pointer"> arrow_back </mat-icon>
    <div class=" ">
      <mat-form-field class="example-full-width w-full search-box m-0 mr-4">
        <span matPrefix> </span>
        <input
          type="text"
          matInput
          placeholder="Search here"
          name="search"
          style="color: black"
          [(ngModel)]="searchText"
          (keyup.enter)="search()"
        />
        <button matSuffix mat-button class="ml-15">
          <mat-icon (click)="search()" >search</mat-icon>
        </button>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        class="text-xl"
        (click)="addCustomer()"
      >
        Add
      </button>
    </div>
  </div>
  <h3 *ngIf="!type">Our customers</h3>
  <h3 *ngIf="type == 'employee'">Our employees</h3>
  <div
    *ngIf="!customers?.length"
    class="flex justify-center"
    style="color: #ff4f5a"
  >
    <img src="assets/images/noData.avif" style="width: 40%" />
  </div>
  <div class="example-container mat-elevation-z8" *ngIf="customers?.length">
    <mat-table #table [dataSource]="customers">
      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
      <ng-container matColumnDef="id" *ngIf="type != 'employee'">
        <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element?.userId }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element?.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <mat-header-cell *matHeaderCellDef> Mobile number </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.mobile?.number }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="address" *ngIf="type != 'employee'">
        <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.address }}
        </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="address" *ngIf="type != 'employee'">
        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell>
         <mat-icon>
        </mat-cell>
      </ng-container> -->
            
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions </mat-header-cell>
            <mat-cell *matCellDef="let element"> <mat-icon (click)="$event.stopPropagation(); edit(element)"> edit</mat-icon> </mat-cell>
          </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onClick(row)"
        style="cursor: pointer"
      ></mat-row>
    </mat-table>
  </div>
  <div class="loadmore flex justify-center m16">
    <button
      class="btn-button"
      mat-raised-button
      color="primary"
      *ngIf="customers?.length && count > customers.length"
      (click)="loadMore()"
    >
      Load more
    </button>
  </div>
</div>
<app-footer> </app-footer>
