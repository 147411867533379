<div style="max-height: 70vh; overflow: auto;">
<div class="flex justify-end">
    <mat-icon class="close-icon" (click)="close()" style="cursor: pointer;"> close </mat-icon>

    </div>
<mat-card *ngFor="let installment of data.item?.installments" style="margin: 10px 0px; ">
    <div  class="flex justify-between mb-15">
<div> Amount paid :   {{installment?.amount}}</div><div *ngIf="installment?.interestDeducted">  Intrest amount deducted : {{installment?.interestDeducted}} </div> <div *ngIf="installment?.prinicpleAmountDeducted"> Prinicple  amount deducted : {{installment?.prinicpleAmountDeducted}} </div> <div> Paid at : {{installment?.paidAt | date:'dd/MM/yyyy hh:mm:ss'}} </div> <div> <mat-icon style="cursor: pointer;" (click)="download(installment,data.item)"> download </mat-icon> </div>
</div>
</mat-card>
</div>