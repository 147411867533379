import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { CustomersComponent } from './components/customers/customers.component';
import { RouterModule } from '@angular/router';
import {MatTableModule} from '@angular/material/table';
import { CustomersViewComponent } from './components/customers-view/customers-view.component';
import { InstallmentsComponent } from './components/installments/installments.component';
import { AddSavingsComponent } from './components/add-savings/add-savings.component';
import { CreateSchemComponent } from './components/create-schem/create-schem.component';
import { MatSelectModule } from '@angular/material/select';
import { MakePaymentComponent } from './components/make-payment/make-payment.component';
import { GenericConfirmPopupComponent } from './components/generic-confirm-popup/generic-confirm-popup.component';
import { FooterComponent } from './components/footer/footer.component';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { AddTakeOverComponent } from './components/add-take-over/add-take-over.component';

@NgModule({
  declarations: [AddCustomerComponent,GenericConfirmPopupComponent, CustomersComponent, CustomersViewComponent, InstallmentsComponent, AddSavingsComponent, CreateSchemComponent, MakePaymentComponent, FooterComponent, ImageViewComponent, AddTakeOverComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    FormsModule,ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule
  ],
  exports:[
    AddCustomerComponent,
    MatInputModule,
    MatFormFieldModule,
    CustomersComponent,
    FooterComponent,
    ImageViewComponent
  ]
})
export class CoreModule { }
