import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { urls } from 'src/app/core/constants/urls';
 import { ApiService, CurrentUserService, ToastService } from '../../core'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginValid : boolean = false;
  user:any={};
  hide=true;
  auth:FormGroup | undefined;
  loginForm : FormControl;

  constructor(
    private apiService:ApiService,
    private toast : ToastService,
    private userService : CurrentUserService,
    private router : Router,
  ) { }

  ngOnInit(): void {
    
  }
  submit(){
    const config ={
      url : urls.login,
      payload:this.user
    }
    this.apiService.post(config).subscribe(data =>{
      if(data.result){
        this.toast.success(data.message);
        this.userService.setUser(data.result);
          this.router.navigate(['admin/dashboard']);
      }else{
        if(data.message){
          this.toast.error(data.message);
        }
      }
    },error =>{
      this.toast.error(error.message);
    })
  }
}