import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { urls } from '../../constants/urls';
import { ApiService, UtilsService,ToastService } from '../../services';
@Component({
  selector: 'app-add-savings',
  templateUrl: './add-savings.component.html',
  styleUrls: ['./add-savings.component.scss']
})
export class AddSavingsComponent implements OnInit {

  customer!: FormGroup;
  organizationId:any;
  constructor( public dialogRef: MatDialogRef<AddSavingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils : UtilsService,
    private api:ApiService,
    private toast : ToastService) { }

   ngOnInit() {
    this.customer = new FormGroup({
      name: new FormControl(this.data ? this.data.name : ''),
      mobile: new FormControl(this.data ? this.data.mobile : '', [Validators.required, Validators.minLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      email: new FormControl(this.data ? this.data.email : '', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
    });
  }
  get f() {
    return this.customer.controls;
  }

  async add(){
    this.organizationId = await this.utils.getOrganiztionId();
    this.customer.value.organisationId =  this.organizationId;
    const config ={
      url:urls.add_customer,
      payload:this.customer.value
    }
    this.api.post(config).subscribe(resp =>{
      if(resp.result){
        this.toast.success(resp.message)
        this.dialogRef.close();
      }else{
        this.toast.error(resp.message);
      }
    })
  }
  cancel(){
    this.dialogRef.close();
  }
}
