import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { urls } from "../../constants/urls";
import { ApiService, UtilsService, ToastService } from "../../services";
@Component({
  selector: "app-make-payment",
  templateUrl: "./make-payment.component.html",
  styleUrls: ["./make-payment.component.scss"],
})
export class MakePaymentComponent implements OnInit {
  scheme!: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<MakePaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utils: UtilsService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.scheme = new FormGroup({
      amount: new FormControl(
        this.data ? this.data.amount : "",
        Validators["required"]
      ),
      // balance: new FormControl(this.data ? this.data.balance : '',Validators['required']),
    });
  }
  async add() {
    let data = {
      header: "",
      message: "Are you sure, you want to pay installment?",
    };
    this.utils.openDialog(data).then((resp) => {
      if (resp) {
        this.dialogRef.close(this.scheme.value);
      }
    });
  }
  cancel() {
    this.dialogRef.close();
  }
}
